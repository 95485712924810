import React from 'react'
import { Pie } from 'react-chartjs-2'
import {ArcElement, Chart as ChartJS, Legend, Tooltip} from 'chart.js'

ChartJS.register(
  ArcElement,
  Legend,
  Tooltip
)

const ConditionsPhqPieChart = ({ phqCounts }) => {
  console.log(phqCounts)
  const data = {
    labels: ['全く', '数日', '半分以上', 'ほとんど毎日'],
    datasets: [
      {
        data: [phqCounts.notAtAll, phqCounts.severalDays, phqCounts.moreThanHalfTheDays, phqCounts.nearlyEveryDay],
        backgroundColor: ['#4bc0c04f', '#4bc0c09f', '#4bc0c0df', '#4bc0c0ff'],
        hoverBackgroundColor: ['#4bc0c0', '#4bc0c0', '#4bc0c0', '#4bc0c0'],
      },
    ],
  }

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
        labels: {
          boxWidth: 0,
          boxHeight: 0,
        },
      },
      title: {
        display: false,
      },
    },
  }

  return (
    <Pie data={data} options={options} />
  )
}

export default ConditionsPhqPieChart
