import React from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
)

const OrganizationCounselingReserveBarChart = ({ reports }) => {
  const labels = Object.keys(reports)
  const values = Object.values(reports)

  // 最大予約数が80を上回る場合下限と上限の表示を調整する
  const maxValue = Math.max(...values)
  const yMin = maxValue > 80 ? 20 : 0
  // 上限は最大値より大きい最小の１０の倍数  最大値108の場合 110
  const yMax = maxValue > 80 ? Math.ceil(maxValue / 10) * 10 : 80

  const options = {
    plugins: {
      title: {
        display: false,
      },
      legend: {
        position: 'top',
        labels: {
          boxWidth: 0,
          boxHeight: 0,
        },
      },
    },
    scales: {
      y: {
        min: yMin,
        max: yMax,
      }
    },
  }

  const data = {
    labels,
    datasets: [
      {
        label: '予約数',
        animation: false,
        data: values,
        backgroundColor: '#81be50',
      },
    ],
  }

  return <Bar options={options} data={data} />;
}

export default OrganizationCounselingReserveBarChart
