import React from 'react'
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  LinearScale,
  Title,
  Tooltip,
  CategoryScale,
  Legend,
  LineController,
  Filler
} from 'chart.js'
import { Line } from 'react-chartjs-2'

ChartJS.register(
  LineElement,
  PointElement,
  LinearScale,
  Title,
  Tooltip,
  CategoryScale,
  Legend,
  LineController,
  Filler
)

const ConditionsLineChart = ({ weekData, monthData, yearData }) => {
  const [tab, setTab] = React.useState('week')

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: 'top',
        labels: {
          boxWidth: 0,
          boxHeight: 0,
        },
      },
      title: {
        display: false,
      },
    },
    scales: {
      y: {
        min: 1,
        max: 5,
        ticks: {
          stepSize: 1,
        },
      },
      x: {
        grid: {
          display: false,
        },
      },
    },
    elements: {
      line: {
        fill: true,
      },
    },
  }

  const handleTabChange = (newTab) => {
    setTab(newTab)
  }

  const formatChartData = (data) => ({
    labels: data.map((d) => d.date),
    datasets: [
      {
        label: '調子',
        data: data.map((d) => d.condition),
        borderColor: 'rgb(75, 192, 192)',
        backgroundColor: '#4bc0c04f',
        tension: 0.1,
        fill: true,
      },
    ],
  })

  return (
    <div>
      <div className="w-full flex justify-center">
        <button onClick={() => handleTabChange('week')} className={`w-1/3 border border-gray-300 py-2 px-4 ${tab == 'week' ? 'font-bold text-gray-600 bg-green01' : 'font-normal text-gray-600'}`}>
          週
        </button>
        <button onClick={() => handleTabChange('month')} className={`w-1/3 border border-gray-300 py-2 px-4 ${tab == 'month' ? 'font-bold text-gray-600 bg-green01' : 'font-normal text-gray-600'}`}>
          月
        </button>
        <button onClick={() => handleTabChange('year')} className={`w-1/3 border border-gray-300 py-2 px-4 ${tab == 'year' ? 'font-bold text-gray-600 bg-green01' : 'font-normal text-gray-600'}`}>
          年
        </button>
      </div>
      <div className="mt-4 border border-gray-300 p-4">
        {tab === 'week' && weekData && (
          <Line
            data={formatChartData(weekData)}
            options={options}
          />
        )}
        {tab === 'month' && monthData && (
          <Line
            data={formatChartData(monthData)}
            options={options}
          />
        )}
        {tab === 'year' && yearData && (
          <Line
            data={formatChartData(yearData)}
            options={options}
          />
        )}
      </div>
    </div>
  )
}

export default ConditionsLineChart
