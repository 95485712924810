import React from 'react'
import { Pie } from 'react-chartjs-2'
import {ArcElement, Chart as ChartJS, Legend, Tooltip} from 'chart.js'

ChartJS.register(
  ArcElement,
  Legend,
  Tooltip
)

const ReserveCategoryPieChart = ({ categoryPoints }) => {
  const data = {
    labels: ['仕事', 'プライベート', '健康'],
    datasets: [
      {
        data: [categoryPoints.jobPoint || 0, categoryPoints.privatePoint || 0, categoryPoints.healthPoint || 0],
        backgroundColor: ['#eb9c24', '#95c055', '#ffed53'],
        hoverBackgroundColor: ['#eb9c24', '#95c055', '#ffed53'],
      },
    ],
  }

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
        labels: {
          boxWidth: 0,
          boxHeight: 0,
        },
      },
      title: {
        display: false,
      },
    },
  }

  return (
    <Pie data={data} options={options} />
  )
}

export default ReserveCategoryPieChart
